

import React, { useEffect, useState } from 'react'
import Cross from "../images/cross.png"
import api from "../api";
import { GoogleMap, LoadScript, Marker, } from "@react-google-maps/api";

const Map =  ({ setMap, vendor_id, phone, address ,city,state})  => {
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyB5EIgTFHJi57gLhDCq6Vv3wUMG_xZCK24"
  // })
  // const lat = '31.5843';
  // const lon = '74.3828';
  
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const formatPhoneNumber = (phone) => {
    // Remove any non-numeric characters
    const cleanedNumber = phone.replace(/\D/g, "");
  
    // Extract area code, exchange, and subscriber number
    const areaCode = cleanedNumber.slice(0, 3);
    const exchange = cleanedNumber.slice(3, 6);
    const subscriberNumber = cleanedNumber.slice(6);
  
    // Format the phone number
    return `(${areaCode}) ${exchange}-${subscriberNumber}`;
  }
  useEffect(() => {
    // fetchData();
    // console.log(setMap, vendor_id, phone, address ,"dd")
  }, []);

  // const fetchData = async () => {
  //   setLoader(true)
  //   api("get", `/GetVendorById/${vendor_id}`)
  //     .then((response) => {
  //       console.log("Success Data:", response?.data?.vendor);
  //       setData(response?.data?.vendor)
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     }).finally(() => setLoader(false))
  // };



  return (
    <>
    <div className="map_popup">
      <div className="map_cross_icon">
        <img
          src={Cross}
          alt=""
          onClick={() => {
            setMap(false);
            console.log("Map clicked")
            document.body.style.overflow = 'visible';
          }}
        />
      </div>
      <div className='map_img'>
        <iframe
          width="374"
          height="250"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCJ_EsOqHiaQB23gHur_Y2GuEMfcMfQ47c&q=${address}+${city},${state}`}
        />
      </div>
      <div className='Map_div'>
        <div class="map_container">
          <div class="map_inner-div">
            <div class="titles">Location</div>
          </div>
          <div class="inner-div">
            <div class="address">{address} <br />{city}, {state}</div>
          </div>
        </div>
        <div class="second-container">
          <div class="titles">Call Us</div>
          <div class="inner-div">
            <div>
              <span class="phone">Phone:</span>
              <span class="phone-number">  {formatPhoneNumber(phone)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>


  )
}

export default Map
