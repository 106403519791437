import React from 'react'
import Arrow from "../../images/backarrow.png"

const Backicon = () => {
  return (
    <div>
      <div className='back_icon'>
        <img src={Arrow} alt="" />
      </div>
    </div>
  )
}

export default Backicon
