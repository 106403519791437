import React, { useEffect, useState } from "react";
import Cross from "../../images/cross.png";

const Filter = ({ setFilterization, filterization, setFilters }) => {
  // Handle cross icon click
  const handleCrossIcon = () => {
    setFilters(false);
    document.body.style.overflow = "visible";
  };

  // Handle filter selection
  const handleFilter = (value) => {
    setFilterization(value);
    localStorage.setItem("selectedFilter", value); 
    handleCrossIcon();
  };

  // Apply background color if the filter is selected
  const handleCheckboxColor = (value) => {
    if (filterization === value) {
      return {
        backgroundColor: "#d00d4f"
      };
    }
  };

  // Retrieve the selected filter from localStorage on component mount
  useEffect(() => {
    const savedFilter = localStorage.getItem("selectedFilter");
    if (savedFilter) {
      setFilterization(savedFilter);
    }
  }, [setFilterization]);

  return (
    <div className="filter">
      <div className="filter_heading">
        <h6>Filter By</h6>
        <div className="filter_cross_icon">
          <img src={Cross} alt="" onClick={handleCrossIcon} />
        </div>
      </div>
      <div className="filter_checkbox">
        <div className="checkbox" onClick={() => handleFilter("newest")}>
          <label className="checkbox_label" style={handleCheckboxColor("newest")}></label>
          <span className="label_text">
            Newest to Oldest
          </span>
        </div>
        <hr className="profle_hr" />
        <div className="checkbox" onClick={() => handleFilter("alphabatically")}>
          <label className="checkbox_label" style={handleCheckboxColor("alphabatically")}></label>
          <span className="label_text">
            Alphabetically
          </span>
        </div>
        <hr className="profle_hr" />
        <div className="checkbox" onClick={() => handleFilter("favourite")}>
          <label className="checkbox_label" style={handleCheckboxColor("favourite")}></label>
          <span className="label_text">
            My Favorites Only
          </span>
        </div>
        <hr className="profle_hr" />
      </div>
    </div>
  );
};

export default Filter;
