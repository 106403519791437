import React from 'react';
import BackArrow from "../icons/Backicon";
import { Link, useLocation } from 'react-router-dom';
import winner from '../../images/winner.png';
import api from "../../api";
import { toast } from "react-toastify";

const VerifyEmailScreen = () => {
    const location = useLocation();
    const userId = location.state?.id || location.state?.data?.id;
    console.log(userId, "id");

    const handleResendEmail = () => {
        if (userId) {
            api("post", `/email/verify/sendcode`, { id: userId })
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("Verification email resent. Please check your inbox.");
                    } else {
                        toast.error("Failed to resend verification email. Please try again.");
                    }
                })
                .catch((error) => {
                    console.error("Resend Email Error:", error);
                    toast.error("Failed to resend verification email. Please try again.");
                });
        } else {
            toast.error("User ID not found. Please try signing up again.");
        }
    };

    return (
        <div className="otp_form">
            <Link to="/login">
                <BackArrow />
            </Link>

            <div className="verify_container">
                <h1>Verify Your Email</h1>
                <p className="description">Please verify your email. We have sent you an email to verify.</p>
                <img src={winner} alt="Email Verification" className="image" />
                <p className="additional-description">
                    No email? Please check spam, <strong onClick={handleResendEmail}>resend email</strong>, or try signing up with another email address.
                </p>
            </div>
        </div>
    );
};

export default VerifyEmailScreen;
