import React, { useEffect, useState } from "react";
import Cross from "../../images/cross.png";
import weblink from "../../images/weblink.PNG";
import tennerfacebook from "../../images/tennerfacebooklink.JPG";
import FacebookLink from "../../images/facebooklink.JPG";

const Event = ({ setEvent, tennerEvent }) => {
  const [links, setLinks] = useState({
    web_link: "",
    tenner_facebook_link: "",
    facebook_link: "",
  });

  useEffect(() => {
    if (tennerEvent) {
      setLinks({
        web_link: tennerEvent.web_link || "",
        tenner_facebook_link: tennerEvent.tenner_facebook_link || "",
        facebook_link: tennerEvent.facebook_link || "",
      });
    }
  }, [tennerEvent]);

  const handleButtonClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("URL is invalid or empty:", url);
    }
  };

  return (
    <>
      <div className="event" style={{ height: tennerEvent?.flyer_pic ? '80vh' : '398px' }}>
        <div className="event_heading" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="dynamic_buttons" style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            <img src={weblink} alt="Weblink" onClick={() => handleButtonClick(links.web_link)} style={{ height: '23px', width: '23px', cursor: 'pointer' }} />
            <img src={tennerfacebook} alt="Tenner Facebook" onClick={() => handleButtonClick(links.tenner_facebook_link)} style={{ height: '23px', width: '23px', cursor: 'pointer', margin: '0 3px' }} />
            <img src={FacebookLink} alt="Facebook Link" onClick={() => handleButtonClick(links.facebook_link)} style={{ height: '23px', width: '23px', cursor: 'pointer', margin: '0 3px' }} />
          </div>
          <h6 style={{ width: '50%', textAlign: 'center', margin: 0, paddingRight: '15%' }}>Tenner Events</h6>
          <div className="event_cross_icon">
            <img src={Cross} alt="" onClick={() => { setEvent(false); document.body.style.overflow = "visible"; }} />
          </div>
        </div>

        {!tennerEvent.flyer_pic && (
          <div className="event_circle">
            {tennerEvent.circle_pic && (
              <div className="event_img_back">
                <div className="event_profile_img">
                  <img src={tennerEvent.circle_pic_path + tennerEvent.circle_pic} alt="" />
                </div>
              </div>
            )}
            <div className="event_paragraph_heading">
              <h1>{tennerEvent.title}</h1>
            </div>
            {tennerEvent.description && (
              <div className="event_paragraph">
                <p>{tennerEvent.description}</p>
              </div>
            )}
          </div>
        )}

        {tennerEvent.flyer_pic && (
          <div className="event_flyer" style={{ background: "transparent" }}>
            <div className="flyer">
              <img src={tennerEvent.flyer_pic_path + tennerEvent.flyer_pic} alt="flyerimg" />
            </div>
          </div>
        )}

        {!tennerEvent.flyer_pic && (
          <div className="event_flyer"></div>
        )}
      </div>
    </>
  );
};

export default Event;
