import React, { useState } from "react";
import { Link } from "react-router-dom";
import main from "../../images/main.png"
import Logo from "../../images/Logo.svg";
import Contact from "../popups/Contact";
const Main = () => {
  const [contact, setContact] = useState(false);
  const [menu, setMenu] = useState(false);

  const handleContact = () => {
    window.open('https://business.tennerlocaldeals.com', '_blank');
    // setContact(true);
    // setMenu(false);
    // document.body.style.overflow = "hidden";
  };
  return (
    <>
      <div className="main_div">
        <div className="main_div_inner">
          <div className="logo_img">
            <img src={Logo} alt="" />
          </div>
          <div className="main_text">
          <h1>Save <span className="highlight">Money</span> in Lafayette</h1>
        </div>
        <div className="main_image">
          <img src={main} alt="Main" />
        </div>
          <div className="buttons">
            <div className="button_first" style={{ "text-decoration": "none" }}>
              <Link to="/signup" className="linkRedirect" style={{ "text-decoration": "none" }}>
                <button >Create an account</button>
              </Link>
            </div>
            <div className="button_second">
              <Link to="/login" className="linkRedirect">
                <button>Log In</button>
              </Link>
            </div>
            <div className="main_text2">
      <span onClick={handleContact}>  Are You a Business? </span>
      </div>

      {contact && <Contact setContact={setContact} setMenu={setMenu}  />}
      {contact && (
        <div
          className="side_bar_backgrounds"
          onClick={() => {
            setContact(false);
            setMenu(!menu);
      
            document.body.style.overflow = "visible";
          }}
        />
      )}
      
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
