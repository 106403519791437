import { useEffect, useState } from "react";
import Menubutton from "../../images/menu.png";
import Filter from "../../images/filter.png";
import Logo from "../../images/headerLogo.png";
import Logos from "../../images/logose.svg";
import Logoes from "../../images/logost.svg";
import Dinner from "./Dinner";
import Slider from "./Carousal";
import { toast } from "react-toastify";
import Menu from "../../components/popups/Profile";

import Filters from "../../components/popups/Filter";
import Contact from "../popups/Contact";
import Terms from "../popups/Term";
import api from "../../api";

const Home = () => {
  const [isToastVisible, setToastVisible] = useState(false);
  const [menu, setMenu] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  console.log("🚀 ~ Home ~ sliderData:", sliderData);

  const [filters, setFilters] = useState(false);
  const [filterization, setFilterization] = useState();
  console.log("🚀 ~ Home ~ filterization:", filterization);
  
  const [contact, setContact] = useState(false);
  const [term, setTerm] = useState(false);
  const [termData, setTermData] = useState("");

  
  const handleContact = () => {
    setMenu(!menu)
    setContact(!contact);
    document.body.style.overflow = "hidden";
  };
  useEffect(() => {
    const savedFilter = localStorage.getItem("selectedFilter");
    console.log(savedFilter,"fffff")
if(savedFilter){
  setFilterization(savedFilter)
}else{
  localStorage.setItem("selectedFilter", "newest"); 
  setFilterization("newest")
}
  }, [])
  
  const handleTerm = async () => {
    // Handle form submission logic here
    await api("get", "/page/term_conditions")
      .then((response) => {
        // Handle successful response here
        setMenu(false)
        setTermData(response.data.data.content);
      })
      .catch((error) => {
        // Handle error here
        console.error("Error:", error);
      });
    setTerm(!term);
    document.body.style.overflow = "hidden";
  };
  
  
  const handleMenu = () => {
    setMenu(!menu);
    document.body.style.overflow = "hidden";
  };

  const handleFilters = () => {
    setFilters(!filters);
    document.body.style.overflow = "hidden";
  };


  // const applyFilterization = () => {
  //   let sortedData = [...sliderData];

  //   if (filterization === "Favorites") {
  //     sortedData.filter((item) => item.is_featured === 1);
  //   } else if (filterization === "Newest") {
  //     // Sort based on newest (created_at)
  //     sortedData.sort(
  //       (a, b) => new Date(b.created_at) - new Date(a.created_at)
  //     );
  //   } else if (filterization === "Alphabetically") {
  //     sliderData.slice().sort((a, b) => {
  //       const nameA = (a.name || "").toLowerCase();
  //       const nameB = (b.name || "").toLowerCase();
  //       return nameA.localeCompare(nameB);
  //     });
  //   }

  //   // Update filtered data
  //   setSliderData(sortedData);
  //   toast.success("Filterization Apply");
  // };
  // useEffect(() => {
  //   // Apply filterization when sliderData changes
  //   applyFilterization();
  // }, [filterization]);

  return (
    <>
      {menu && <Menu setMenu={setMenu} isToastVisible={isToastVisible} setToastVisible={setToastVisible}  
         handleContact={handleContact} handleTerm={handleTerm}
      />}
      {menu && (
        <div
          className="side_bar_background"
          onClick={() => {
            setMenu(false);
            document.body.style.overflow = "visible";
          }}
        />
      )}

      {filters && filterization !== "" &&  (
        <Filters setFilterization={setFilterization} filterization={filterization} setFilters={setFilters} />
      )}
      {filters && (
        <div
          onClick={() => {
            setFilters(false);
          }}
        />
      )}

      {contact && <Contact setContact={setContact}  setMenu={setMenu}/>}
      {contact && (
        <div
          className="side_bar_backgrounds"
          onClick={() => {
            setContact(false);
            setMenu(!menu)
            document.body.style.overflow = "visible";
          }}
        />
      )}

      {term && <Terms setTerm={setTerm} termData={termData} setMenu={setMenu}/>}
      {term && (
        <div
          className="side_bar_backgrounds"
          onClick={() => {
            setTerm(false);
            setMenu(!menu)
            document.body.style.overflow = "visible";
          }}
        />
      )}

      <div className="Homepage">
        <div className="home_inner">
          <div className="home_top">
            <div className="home_logo">
              <img src={Logo} alt="" />
              {/* <div className="log_top">
                <img src={Logoes} alt="" />
                <img src={Logos} alt="" />
              </div>
              <div className="logo_down">
                <span style={{color:"#cb4339",fontSize:".5rem"}}>Local Deals</span>
              </div> */}
            </div>
          </div>
          <div className="home_top_icon">
            <img src={Filter} alt="" onClick={handleFilters} className='icons-style' />
            <img src={Menubutton} alt="" onClick={handleMenu} className='icons-style'/>
          </div>
        </div>
        <div style={{marginTop:'-4rem'}}>
          { filterization !== "" && 
          <Slider sliderData={sliderData} setSliderData={setSliderData} filterization={filterization} />}
        </div>
        <div>
          <Dinner />
        </div>
        {isToastVisible && <div className="toast">Copied to clipboard!</div>}
      </div>
    </>
  );
};

export default Home;


// AIzaSyD_sTSvkRNPj0zafvMKypIio_IqeyOipUg