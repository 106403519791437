import React, { useState } from 'react'
import Winners from '../../images/winner.gif'
import Cross from "../../images/cross.png"
import Share from "../../images/share.svg"

const Winner = ({setWinner, offer_id}) => {
  const [isToastVisible, setToastVisible] = useState(false);

  const handleCopyTextToClipboard = (text) => {
    // Create a temporary text area element
    const textArea = document.createElement("textarea");
    textArea.value = text;
  
    // Set styles to make copying work in various environments
    textArea.style.position = "fixed";  // Avoid scrolling to bottom
    textArea.style.left = "-9999px";    // Element is not visible
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      // Attempt to execute the copy command
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log(`Copying text command was ${msg}`);
    } catch (err) {
      console.error('Oops, unable to copy', err);
    }
  
    // Remove the temporary text area
    document.body.removeChild(textArea);
  };
  
  const copyTextToClipboard = async () => {
    try {
      // await navigator.clipboard.writeText("https://google.com");
      handleCopyTextToClipboard(window.location.origin+`/shared-preview/${offer_id}`)
      setToastVisible(true);
      setTimeout(() => {
        setToastVisible(false);
      }, 2000); // Hide the toast after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };



  return (
    <>
    <div className="winner">
    <div className="winner_top">
      <div className="winner_heading">
        <h6>Winner Winner Tenner Dinner!</h6>
        <div className="winner_cross_icon">
        <img
            src={Cross}
            alt=""
            onClick={() => {
              setWinner(false);
              document.body.style.overflow = "visible";
            }}
          />
        </div>
        </div>
        <div className="winner_paragraph">
        <p>Show this to your server when checking out to redeem your exclusive Tenner Special!</p>
        </div>
        </div>
     
       <div className="winner_img">
        <img src={Winners} style={{width: "300px"}} alt="" />
       </div>
       <div className="winner_span">
        <span>Share this deal with your friends</span>
       </div>
       <div className="winner_button">
        <button onClick={copyTextToClipboard}><img src={Share} alt="" />Share</button>
       </div>
       {isToastVisible && <div className="toast">Copied to clipboard!</div>}
   </div></>
  )
}

export default Winner