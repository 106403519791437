import React, { useState, useEffect, useRef } from "react";
import { Oval } from "react-loader-spinner";
import Cross from "../images/cross.png";

const Reel = ({ setReel, reelMedia }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [progressComplete, setProgressComplete] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoStarted, setVideoStarted] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (reelMedia?.media) {
      const video = document.createElement("video");
      video.src = reelMedia.media;

      video.addEventListener("loadedmetadata", () => {
        setVideoDuration(video.duration);
      });

      video.addEventListener("canplaythrough", () => {
        setIsLoading(false);
      });

      return () => {
        video.removeEventListener("loadedmetadata", () => {});
        video.removeEventListener("canplaythrough", () => {});
      };
    }
  }, [reelMedia?.media]);

  const handleAnimationEnd = () => {
    setProgressComplete(true);
    setReel(false);
    document.body.style.overflow = "visible";
  };

  const getAnimationStyle = () => ({
    animation: `progressAnimation ${videoDuration}s ease-in-out forwards`,
  });

  const handleVideoPlay = () => {
    setVideoStarted(true);
  };

  return (
    <div
      className="custom-container"
      style={{
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1500,
        paddingTop: "-15%",
        display: "flex",
      }}
    >
      {/* Loader container, shown above the video while loading */}
      {(!videoStarted || isLoading) && (
        <div
          className="loader-container"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop:'75%',
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1501, 
          }}
        >
          <Oval height="80" width="80" color="#4fa94d" />
        </div>
      )}

      {/* Video content */}
      <div className="video-wrapper">
        {reelMedia?.url?.includes("image") ? (
          <img
            className="custom-img video_bg"
            src={reelMedia.media}
            alt="Placeholder"
          />
        ) : (
          <video
            className="custom-img video_bg"
            autoPlay
            ref={videoRef}
            onCanPlayThrough={() => setIsLoading(false)}
            onPlay={handleVideoPlay}
          >
            <source src={reelMedia.media} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      {/* Show progress bar only after video starts playing */}
      {!isLoading && videoStarted && !progressComplete && (
        <div className="progress-line"  style={{marginTop:'-15%'}}>
          <div className="progress-bar-background" />
          <div
            className="progress-bar"
            style={getAnimationStyle()}
            onAnimationEnd={handleAnimationEnd}
          ></div>
        </div>
      )}

      {/* UI overlay */}
      <div className="gradient-overlay"  />
      <div className="logo-container" >
        <div className="avatar-container" style={{marginTop:'-23%'}} >
          <div className="reel_avatar">
            <div className="inner-avatar">
              <div className="outer-avatar" />
              <img className="avatar-image" src={reelMedia.logo} alt="Avatar" />
            </div>
          </div> 
          <div className="title">{reelMedia?.title}</div>
        </div>
        <div className="close-button" style={{marginTop:'-23%'}}>
          <img
            src={Cross}
            alt="Close"
            onClick={() => {
              setReel(false);
              document.body.style.overflow = "visible";
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Reel;
