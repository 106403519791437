import React, { useEffect, useState, } from 'react'
import Logos from "../../images/logose.svg";
import Logoes from "../../images/logost.svg";
import { Link, useParams } from "react-router-dom";
import api from "../../api";
import { Store } from "../../StoreContext";
import Winner from "../popups/Winner";
import location from "../../images/loation.svg";
import Reel from "../Reel";
import Map from "../Map";
import { LoadScript } from '@react-google-maps/api';

const r_logo_path = 'https://tennerdealsapi.envobyte.dev/assets/admin/img/vendor-photo/'
const media_content_path = 'https://tennerdealsapi.envobyte.dev/assets/admin/img/vendor-photo/media-content/'
function SharedPreview() {
    const { id }  =  useParams();
    const { user } = Store();
    const [reel, setReel] = useState(false);
    const [winner, setWinner] = useState(false);
    const [reelMedia, setReelMedia] = useState({
        media: null,
        logo: null,
        mediaType: null,
    });
    const [imagepath, setImagePath] = useState("");
    const [mediaPath, setMediaPath] = useState("");
    const [slide, setOfferData] = useState(null);
    const [error,setError] = useState(false)
    const [vendorIdForMap, setVendorIdForMap] = useState(0)
    const [map, setMap] = useState(false);



    useEffect(()=>{
        fetchSingleOffer()
    },[user.id])

    const fetchSingleOffer = () =>{
        setError(false)
        // https://tennerdealsapi.envobyte.dev/api/v1/event_offers/getById/{event_id}
        api("get", `/event_offers/getById/${id}`)
            .then((response) => {
            console.log("Success Data:", response?.data?.data);
            setImagePath(response?.data?.r_logo_path || r_logo_path);
            setMediaPath(response?.data?.media_content_path || media_content_path);
            setOfferData(response?.data?.data);
            })
            .catch((error) => {
            setError(true)
            console.error("Error:", error);
        });
    }

    const setLogoBorderOnMedia = (media) =>{
    if(media){
        return {
        border:'4px solid #d00d4f'
        }
    }
    }

    const handleReel = (media, logo, url, title) => {
    setReelMedia({ media, logo, url, title });
    setReel(!reel);
    document.body.style.overflow = "hidden";
    };
    
    const handleMap = (id) => {
      setVendorIdForMap(id)
      setMap(!map);
      document.body.style.overflow = "hidden";
    };

    const handleWinner = () => {
        setWinner(!winner);
        document.body.style.overflow = "hidden";
    };
  return (
    <div className="Homepage">
        <div className="home_inner">
          <div className="home_top">
            <div className="home_logo">
              <Link  to={'/home'} className="log_top">
                <img src={Logoes} alt="" />
                <img src={Logos} alt="" />
              </Link>
              <div className="logo_down">
                <span>Local Deals</span>
              </div>
            </div>
          </div>
          <div className="home_top_icon">
            
          </div>
        </div>
        <div>
        
        {
            error?
            <div class="centered-content">
                <div>404</div>
                <p>Offer Not Found.</p>
            </div>
            :
            slide !== null ?
            <>
            {winner && <Winner setWinner={setWinner} offer_id={id}/>}
            {winner && (
                <div
                className="side_bar_background"
                onClick={() => {
                    setWinner(false);
                    document.body.style.overflow = "visible";
                }}
                />
            )}

            {reel && <Reel reelMedia={reelMedia} setReel={setReel} />}
            {reel && (
                <div
                className="side_bar_backgroundss"
                onClick={() => {
                    setReel(false);
                    document.body.style.overflow = "visible";
                }}
                />
            )}
            <LoadScript googleMapsApiKey="AIzaSyCJ_EsOqHiaQB23gHur_Y2GuEMfcMfQ47c">

              {map && <Map setMap={setMap} vendor_id={vendorIdForMap}  />}
              {map && (
                <div
                  className="side_bar_background"
                  onClick={() => {
                    setMap(false);
                    document.body.style.overflow = "visible";
                  }}
                />
              )}
              </LoadScript>
           
            
            <div className='slider_back '>
            <div className="slider_box" key={slide?.id} style={{margin:'20% auto'}}>
                <div className="Slider_logo">
                  <div
                    className="logo_top" style={setLogoBorderOnMedia(slide?.media_type)}
                    onClick={() =>
                      {
                        slide?.media_type && 
                        handleReel(
                        mediaPath + slide?.media_content,
                        imagepath + slide?.r_logo,
                        slide?.media_type,
                        slide?.rest_title
                      )}
                    }
                  >
                    <img src={imagepath + slide?.r_logo} alt="" />
                  </div>
                  <div className="location">
                    <img src={location} alt="" onClick={()=>{
                      slide?.latitude && slide?.longitude && handleMap(slide?.vendor_id)
                      }} />
                  </div>
                </div>
                <div className="slide_head">
                  <span>{slide?.rest_title}</span>
                </div>
                <div className="slider_heading_price">
                  <div className="slider_heading">
                    <span>{slide?.offer_title}</span>
                  </div>
                  <div className="Price">
                    <p>
                      <span style={{ display: "flex" }}>
                        {slide?.new_price !== null &&
                        slide?.old_price !== null ? (
                          <div className="price_container">
                            <span className="old_price" style={{textDecoration:'line-through',color:"#5f5f5f"}}>{slide.old_price}$</span>     
                            <span className="new_price">{slide.new_price}$</span>                            
                          </div>
                        ) : slide?.new_price !== null ? (
                          <span>{slide.new_price}</span>
                        ) : slide?.old_price !== null ? (
                          <span>{slide.old_price}</span>
                        ) : slide?.discount_amount !== null ? (
                          <span>{slide.discount_amount}%</span>
                        ) : slide?.offer_type !== null ? (
                          <span>{slide.offer_type}</span>
                        ) : (
                          <span>No Price Available</span>
                        )}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="slide_detail">
                  <p>{slide?.description}</p>
                </div>
                <div className="slide_icon_button shared_redeem" >
                  {/* <div
                    className="love_icon"
                    onClick={() => {
                      slide?.favourite.length > 0 && slide?.favourite?.[0].user_id == user.id ?
                      removeItemFromFavouriteList(slide?.favourite?.[0].id, slide.id):
                      handleAddFavorite(slide.id)
                      
                      }}
                  >
                    <img src={
                      slide?.favourite.length > 0 && slide?.favourite[0].user_id == user.id ?
                      LoveRed:
                      Love
                      } alt="" className="love_icon_image" />
                  </div> */}
                  <div className="slidebutton redeem_btn">
                    <button onClick={handleWinner}>Redeem Now</button>
                  </div>
                </div>
              </div>
              </div>
            </>:
            null
        }
        
        
        
        
        </div>
        
      </div>
  )
}

export default SharedPreview
