import React from "react";
import Cross from "../../images/cross.png";
const Term = ({ setTerm, termData, setMenu }) => {
  return (
    <>
      <div className="terms">
        <div className="terms_heading">
          <h6>Terms of Service</h6>
          <div className="term_cross_icon">
            <img
              src={Cross}
              alt=""
              onClick={() => {
                setTerm(false);
                setMenu(true)
                document.body.style.overflow = "visible";
              }}
            />
          </div>
        </div>
        <div className="term_paragraph">
          {termData}
          
        </div>
      </div>
    </>
  );
};

export default Term;
